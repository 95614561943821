import React from "react";
const config = require("../../../data/SiteConfig")

class ThreeColumnWithBadges extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        category: postEdge.node.frontmatter.category,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <div>

<div className="bg-white pt-10 pb-10 px-4 sm:px-6 lg:pt-10 lg:pb-10 lg:px-8">
  <div className="relative max-w-lg mx-auto lg:max-w-7xl">
    <div>
      <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
        {config.blogTitle}
      </h2>
      <p className="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
        {config.blogDecription}
      </p>
    </div>
    <div className="mt-12 grid gap-16 border-t-2 border-gray-100 pt-12 lg:grid-cols-3 lg:col-gap-5 lg:row-gap-12">


    



        {/* Your post list here. */
        postList.map(post => (
          <a href={post.path} key={post.title}>
            <div>
                <div>
                    <span className="inline-flex items-center px-3 py-0.5 rounded text-sm font-medium leading-5 bg-indigo-100 text-indigo-800 capitalize">
                        {post.category}
                    </span>
                </div>
                <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
                    {post.title}
                </h3>
                <p className="mt-3 text-base leading-6 text-gray-500">
                    {post.excerpt}
                </p>
            </div>
          </a>
        ))}



    </div>
  </div>
</div>



      </div>
    );
  }
}

export default ThreeColumnWithBadges;
